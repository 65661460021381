<template>
	<div class="ele-body">
    
    
    
    
		<el-card shadow="never">
      
      
      <el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
      	@submit.native.prevent>
      	<el-form-item label="类型:" label-width="47px" class="w-170">
      		<el-select v-model="table.where.child_type" clearable>
      			<el-option value="all" label="全部"></el-option>
            <el-option value="g2" label="快捷消息"></el-option>
            <el-option value="g1" label="平台消息"></el-option>
           
      		</el-select>
      	</el-form-item>
        <el-form-item label="省:" label-width="35px" class="w-170">
      		<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
      			<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
      			</el-option>
      		</el-select>
      	</el-form-item>
      	<el-form-item label="市:" label-width="35px" class="w-150">
      		<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid),$forceUpdate()"
      			placeholder='请选择市' clearable>
      			<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
      			</el-option>
      		</el-select>
      	</el-form-item>
      	<el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
      		<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
      			<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
      				:label="option.name"></el-option>
      		</el-select>
      	</el-form-item>
      	<el-form-item>
      		<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
      			class="ele-btn-icon">搜索</el-button>
      		<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
      	</el-form-item>
      </el-form>
      
			<ele-data-table ref="table" :config="table" :choose.sync="choose" stripe class="my-dialog-name">
				<template slot-scope="{index}">
					<el-table-column type="index" :index="index" label="编号" width="60" align="center"
						show-overflow-tooltip />
					<el-table-column prop="realname" label="接收人" show-overflow-tooltip min-width="60" />
					<el-table-column prop="title" label="标题" show-overflow-tooltip min-width="100" />
					<el-table-column prop="cn_type" label="消息类型" show-overflow-tooltip min-width="80" />
         
          <el-table-column prop="cn_create_time" label="日期" show-overflow-tooltip min-width="80" />
          
          <el-table-column label="操作" width="120px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link @click="edit(row)" icon="el-icon-view" type="primary" :underline="false"
								>查看</el-link>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
    
    
    
    
		<!-- 查看弹窗 -->
		<el-dialog v-dialogDrag title="消息内容" :visible.sync="showEdit" @closed="editForm={}" :destroy-on-close="true"
			:lock-scroll="false">
			<el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">
				<el-card shadow="never">
					<el-form-item label="消息标题：" prop="title">
						<el-input type="text" placeholder="请输入内容" v-model="editForm.title" disabled> </el-input>
					</el-form-item>
					<el-form-item label="简介：" prop="name">
						<el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="editForm.brief" disabled>
						</el-input>
					</el-form-item>
					<el-form-item label="发布内容：" prop="content">
						<div v-html="editForm.content" style="border: 1px solid #C6C6C6;padding: 20px;">
							{{editForm.content}}
						</div>
					</el-form-item>

				</el-card>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		name: "SysCountyCircle",
		data() {
			return {
				table: {
					url: '/message/adminMyMsgInfoList',
					where: {}
				}, // 表格配置
				provArr: [],
				cityArr: [],
				districtArr: [],
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
				editForm: {}, // 表单数据
				editRules: {}, // 表单验证规则
				noticeData: []
			}
		},
		created() {
			//console.log("a")
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})
			this.$http.get('/message/myMessageList?page=1&limit=10').then(res => {
				let data = JSON.parse(JSON.stringify(res.data))
				this.noticeData = data.data
			})
		},
		computed: {
			...mapGetters(["permission"]),
		},
		mounted() {
		},
		methods: {
			aaaa() {
				//console.log("c",this.noticeData)
			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.area_id = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			dropClick(command, row) {
				if (command === 'through') { //通过
					this.handleThrough(row)
				} else if (command === 'refused') { //拒绝
					this.handleRefused(row)
				}
			},
			/**
			 *修改省
			 **/
			handleChangeProv1(e) {
				//console.log(e)
				/** 获取被选省份的pid**/
				var pid = ''
				this.provArr.forEach(function(item) {
					if (item.name == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cname = ''
					this.editForm.aname = ''
				})
			},
			/**
			 *修改市
			 **/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				var cid = ''
				this.cityArr.forEach(function(item) {
					if (item.name == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aname = ''
				})
				}else{
					this.editForm.pname = ''
					this.editForm.aname = ''
				}
			},
      
			/**
			 * 显示编辑
			 */
			edit(row) {
        //console.log('rrrrrreeeeeeee',row.id);
				this.$http.get('/message/adminMsgInfoDetails?id=' + row.id).then(res => {
          
					if (res.data.code === 0) {
						this.showEdit = true;
						this.editForm = res.data.data;
					} else {
						this.$message.error(res.data.msg);
					}
          
          
          this.$http.get('/message/kjMsgDoRead?id=' + row.id);
          
          
          
				}).catch(e => {
					this.$message.error(e.message);
				});
        
			},
			/**
			 * 保存编辑
			 */
			save() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/countycircle/edit', this.editForm).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.showEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			/**
			 * 刪除(批量刪除)
			 */
			remove(row) {
				this.$confirm('确定要删除此线圈记录吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/countycircle/county_circle_del', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})

			},
		}
	}
</script>

<style scoped>
	.ele-block>>>.el-upload,
	.ele-block>>>.el-upload-dragger {
		width: 100%;
	}
</style>
